/* FONTS DEFINITION */

/* @font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato-ExtraLight.ttf') format('ttf');
  font-weight: 200;
  font-style: normal;
} */

@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato-Light.ttf') format('ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato-Regular.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}


/* @font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato-SemiBold.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
} */

@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato-Bold.ttf') format('ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato-Black.ttf') format('ttf');
  font-weight: 900;
  font-style: normal;
}

/* TAILWIND IMPORT */
@tailwind base;
@tailwind components;
@tailwind utilities;


/* CUSTOM CSS */
html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  @apply bg-black;
}